<template>
    <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotDataExport' }">自定义查询</el-breadcrumb-item>
          <el-breadcrumb-item>新增项目</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
            <el-button size="small" @click="$router.push('/iotDataExport')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        form: {},
        rules: {
          name:[{ required: true, message: '必填项', trigger: 'blur' }],
        },
        defaultProps: {
          children: 'childTree',
          label: 'name'
        },
        id: 0
      }
    },
    methods: {
      onSubmit() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true
            this.$ajax.post("customDataGroupCreate", this.form).then((res) => {
              this.loading = false
              this.$message.success('成功')
              this.$router.push({ name: 'iotDataExport'})
            }).catch(err=>{
              this.loading = false
            })
          }
        })
      }
    },
    mounted() {

    }
  }
  </script>
  
  <style lang="less" scoped>
  .mains {
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .breadcrumb {
    padding: 15px 10px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  </style>